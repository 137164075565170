html {
  margin: 0;
  height: 100%;

  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select: none;
}
body {
  margin: 0;
  height: 100%;
}

button {
  min-width: 50px;
  min-height: 50px;
}
