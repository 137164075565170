.screen {
  position: relative;
  width: 544px;
  height: 544px;
  margin: auto;
}

.icon-bar {
  height: 136px;
  width: 544px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.icon {
  height: 68px;
  width: 68px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.15;
}

.icon.active {
  opacity: 1;
}

.controls {
  max-width: 570px;
  margin: auto;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 100px;
}

#c {
  grid-column-start: 4;
}
